<script>
import {mapGetters} from "vuex";
import Icons from "@/components/reuseables/Icons.vue";
import {createDiscount} from "../../../services/api/APIService";
export default {
  name: "AddDiscountView",
  components: {Icons},
  data() {
    return {
      tagInput: '',
      chips: [],
      imageUrl: null,
      loading : false,
      discountTypes:["Fixed","Percentage"],
      additionalIncentives:["Insurance"],
      discountData:{
        name: '',
        type:'',
        unit:'',
        unitAmount: '',
        startDate: null,
        endDate: null,
        additionalIncentive: '',
        additionalIncentiveUnitAmount: ''
      },
      minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      endMinDate: new Date(  Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      showComponent: true,
      startMenu: false,
      endMenu:false
    };
  },
 computed:{
   preset() {
     return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
   },
   ...mapGetters("paddler",["logInPaddler"]),

 },
  watch:{
    '$route.name':{
      handler : function (val) {
        if( val === "EditBlogPost"){
          this.discountData = this.selectedBlogPost
          this.imageUrl = this.selectedBlogPost.postImage
          this.selectedBlogPost.tags.forEach(tag => {
            this.chips.push({tagName : tag.tagName, color: this.getRandomColor()})
          })
        }
        else {
          this.discountData = {}
        }
      },
      immediate: true
    },
    // 'discountData.startDate':{
    //   handler: (val) =>{
    //
    //     if (val){
    //       this.endMinDate = new Date(  Date.parse(val) - new Date().getTimezoneOffset() * 60000)
    //           .toISOString()
    //           .substr(0, 10)
    //     }
    //   },
    //   immediate: true
    // }
  },
  methods: {
   async createNewDiscount(){
     this.loading = true
     this.discountData.createdByEmail = this.logInPaddler.email
     this.discountData.type = this.discountData.type.toUpperCase();
       createDiscount(this.discountData).then(() =>{
         this.$displaySnackbar({
           message: "Discount created successfully!",
           success: true
         })
         this.loading = false
         this.$router.push({name: 'Discounts'})
       }).catch(err =>{
         this.$displaySnackbar({
           message: err.response.data.details[0],
           success: false,
         })
     }).finally(()=> this.loading = false)

    },

    getRandomColor() {
      const colors = ['blue', 'green', 'red'];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    },
    handleShowComponent(){
      this.showComponent = false
      this.$nextTick(() => {
        this.showComponent = true
      })
    },
    routeBack(){
     this.$store.dispatch("marketing/setSelectedBlogPost",{})
     this.$router.push({name: 'Discounts'})
    }
  },
  created() {

  }
};
</script>

<template>
<div class="blog-container "  v-if="showComponent">
<div class="tw-w-full lg:tw-w-auto">

<div class="tw-w-full">
  <div class="tw-flex header tw-mb-10">
    <div class="tw-cursor-pointer" @click="routeBack">
      <Icons name="return-arrow" class="tw-mr-3 arrow"/>
    </div>
    <h2>Create Discount</h2>
  </div>

  <div class="form-body tw-px-5 tw-py-10">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">

      <form @submit.prevent="handleSubmit(createNewDiscount)">

    <validation-provider name="Discount Title" rules="required" v-slot="{ classes, errors }">
    <h3 class="post-text">Title</h3>
    <v-text-field type="text" class="post-title-input" placeholder="5% discount" solo hide-details v-model="discountData.name"/>
      <span>{{errors[0]}}</span>
      </validation-provider>

       <validation-provider name="Discount type" rules="required" v-slot="{ classes, errors }">
    <h3 class="post-text">Type</h3>
    <v-select type="text" class="post-title-input" :items="discountTypes" placeholder="Percentage" solo hide-details v-model="discountData.type"></v-select>
         <span>{{errors[0]}}</span>
       </validation-provider>
    <validation-provider name="Discount unit" rules="required" v-slot="{ classes, errors }">
      <h3 class="post-text">Unit</h3>
      <v-text-field type="Number" class="post-title-input"  placeholder="5000" solo hide-details v-model="discountData.unit"></v-text-field>
      <span>{{errors[0]}}</span>
    </validation-provider>

    <validation-provider name="Discount unit amount" rules="required" v-slot="{ classes, errors }">
      <h3 class="post-text">Unit Amount <span v-if="discountData.type">({{discountData.type}})</span></h3>
      <v-text-field type="Number" class="post-title-input"  placeholder="5" solo hide-details v-model="discountData.unitAmount"></v-text-field>
      <span>{{errors[0]}}</span>
    </validation-provider>

    <validation-provider name="Start date" rules="required" v-slot="{ classes, errors }">
      <h3 class="post-text">Start Date</h3>
      <v-menu ref="startDateMenu" v-model="startMenu" :close-on-content-click="false"
              :return-value.sync="discountData.startDate" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="discountData.startDate" solo prepend-inner-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" placeholder="2022-01-01" hide-details color="#004aad"></v-text-field>
        </template>
        <v-date-picker v-model="discountData.startDate" no-title scrollable :min="minDate" color="#004aad">
          <v-spacer></v-spacer>
          <v-btn text color="#004aad" @click="startMenu = false">
            Cancel
          </v-btn>
          <v-btn text color="#004aad" @click="$refs.startDateMenu.save(discountData.startDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <span >{{errors[0]}}</span>
    </validation-provider>

    <validation-provider name="End date" rules="required" v-slot="{ classes, errors }">
      <h3 class="post-text">End Date</h3>
      <v-menu ref="endDateMenu" v-model="endMenu" :close-on-content-click="false"
              :return-value.sync="discountData.endDate" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="discountData.endDate" solo prepend-inner-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" placeholder="2022-01-01" hide-details color="#004aad"></v-text-field>
        </template>
        <v-date-picker v-model="discountData.endDate" no-title scrollable :min="discountData.startDate" color="#004aad">
          <v-spacer></v-spacer>
          <v-btn text color="#004aad" @click="endMenu = false">
            Cancel
          </v-btn>
          <v-btn text color="#004aad" @click="$refs.endDateMenu.save(discountData.endDate)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <span >{{errors[0]}}</span>
    </validation-provider>

    <validation-provider name="Additional incentive" rules="" v-slot="{ classes, errors }">
      <h3 class="post-text">Additional Incentive (Optional)</h3>
      <v-select type="text" class="post-title-input" :items="additionalIncentives" placeholder="Food at first stop" solo hide-details v-model="discountData.additionalIncentive"></v-select>
      <span>{{errors[0]}}</span>
    </validation-provider>
    <validation-provider name="Additional incentive amount" rules="" v-slot="{ classes, errors }">
      <h3 class="post-text">Additional Incentive Amount (Optional)</h3>
      <v-text-field type="Number" class="post-title-input"  placeholder="5" solo hide-details v-model="discountData.additionalIncentiveUnitAmount"></v-text-field>
      <span>{{errors[0]}}</span>
    </validation-provider>
    <div class="tw-flex tw-w-full tw-items-end tw-justify-end tw-mt-10">
      <v-btn :width="$vuetify.breakpoint.smAndDown ? '100%': ''"  class="post-button" type="submit" :loading="loading">Create</v-btn>
    </div>
      </form>
    </validation-observer>
  </div>
</div>
  </div>
</div>


</template>

<style scoped lang="scss">

.arrow{
  @media screen and (max-width: 375px){
    margin-right: 20px;
    margin-left: 10px;
  }
}
.blog-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header h2{
  font-family: Inter sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #263238;

  @media screen and (max-width: 375px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
}
.form-body{
  width: 612px;
  min-height: max-content;
  background: #FEFFFF;
  box-shadow: 0 2px 20px 0 #00000008;
  @media screen and (max-width: 820px){
    width: 100%;
    min-height: max-content;
    border-radius: 10px;
    margin-top: 50px;
  }
}


.image-label .image {
  width: 100%;
  height: 192px;
  border-radius: 10px;
  object-fit: contain;
}

.image-label .image[src] {
  max-width: 100%;
  max-height: 300px;
  display: block;
  margin: auto;
  border-radius: 10px;
  object-fit: fill;
  object-position: top;
}

.image-input{
  display: none;
}
.image-label{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 192px;
  border-radius: 10px;
  border: 1px dashed #004aad;
  @media screen and (max-width: 375px){
    height: 258px;
    border-radius: 10px;
    border: 1px dashed #004aad;
    margin-bottom: 40px;

  }
}
.post-title-input{

}

.post-text{
  color: #4F4F4F;
  font-family: Inter,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 1rem;
  margin-top: 1rem;
}
.image{
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  @media screen and (max-width: 375px) {
    margin-top: 40%;
    margin-left: 40%;
  }
}
#post-title-text{
  padding: 0;
  margin-top: 20px;
  @media screen and (max-width: 375px){
  margin-top: 0;
  }
}
::placeholder{
  color:#c4c4c4;
  padding-left: 25px;
  font-family: Inter sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
}


.post-label{

}
.post-label::placeholder{
  margin-left: 30px;
}
.post-button{
  background: #004AAD !important;
  font-family: Inter sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  text-transform: capitalize;
}

.post-button-mobile{
    display: flex;
    width: 258px;
    height: 48px;
    border-radius: 10px;
    //background: #004AAD;
    color: #FFFFFF;
    font-family: Inter sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
}
#chip{
  border-radius: 10px;
  text-align: center;
  font-family: Inter sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #F6F7F9;
}
.chip-container{
  @media screen and (max-width: 375px){
    padding-left: 13px;
  }
}

span{
  color: red;
  font-size: 14px;
  margin-top: 5px;
  //margin-left: 20px;
}

</style>


